import React, { useRef } from "react"

import PropTypes from "prop-types"
import styled from "styled-components"
import { Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { motion } from "framer-motion"

import { useOnScreen } from "../../hooks/"
import ContentWrapper from "../../styles/contentWrapper"
import Underlining from "../../styles/underlining"
import Icon from "../icons"

const StyledSection = styled(motion.section)`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.background};
  margin-top: 6rem;
`

const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
      flex-direction: column;
      justify-content: space-between;
    }
    .section-title {
      padding-right: 2.5rem;
      padding-left: 2.5rem;
      border-left: solid 8px;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-right: 1rem;
        padding-left: 1rem;
      }
    }
    .bn-node-wrapper {
      display: block;
      width: 100%;
      border-bottom: solid 1px;
      padding-bottom: 1.25rem;
      margin-top: 1.25rem;
      &:last-child {
        border-bottom: none;
      }
    }
    .bn-node {
      display: block;
      padding: 0.5rem;
      filter: grayscale(20%) contrast(1) brightness(90%);
      transition: all 0.3s ease-out;
      &:hover {
        background: ${({ theme }) => theme.colors.tertiary};
      }
    }
    h4 {
      margin-left: 1.5rem;
    }
    .bn-title {
    }
    .bn-date {
      font-size: 1.375rem;
    }
    .bn-body {
    }
  }
`

const BackNumbers = ({ content }) => {
  const backnumbers = content.slice(1, 4)

  // Required for animation
  const ref = useRef()
  const onScreen = useOnScreen(ref)
  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  }

  return (
    <StyledSection
      id="backnumbers"
      ref={ref}
      variants={variants}
      animate={onScreen ? "visible" : "hidden"}
    >
      <StyledContentWrapper>
        <h3 className="section-title">バックナンバー</h3>
        <div className="backnumbers">
          {backnumbers.map((backnumber, key) => {
            const { body, frontmatter } = backnumber.node
            return (
              <div className="bn-node-wrapper" key={key}>
                <a
                  className="bn-node"
                  href={`/${frontmatter.file}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <h4>
                    <div className="bn-title">
                      <Icon name="newspaper" color="black" />{" "}
                      {frontmatter.title}
                    </div>
                    <div className="bn-date">{frontmatter.date}</div>
                  </h4>
                  <div className="bn-body">
                    <MDXRenderer>{body}</MDXRenderer>
                  </div>
                </a>
              </div>
            )
          })}
        </div>
        <Link to="/backnumbers">
          <Underlining highlight>
            <strong>全バックナンバー一覧はこちら</strong>
          </Underlining>
        </Link>
      </StyledContentWrapper>
    </StyledSection>
  )
}

BackNumbers.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.object.isRequired,
        body: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
}

export default BackNumbers
